<template>
  <div class="w-100" style="padding: 15px 10px;">
    <section class="w-100 products" v-if="!isOpenVariant">
      <div
        class=""
        v-if="areNotAvailableProducts"
        style="height: 70vh; overflow-y: scroll; overflow-x: hidden"
      >
        <LoaderComp v-if="isLoadingImport" />
        <div
          v-else
          class="row question-not-font flex-column d-flex justify-content-center align-items-center"
        >
          <LazyImage
            :src="'/assets/img/noData.svg'"
            alt=""
            style="width: 15rem"
            class="mt-5"
          />
          <div class="row text-center px-2 mt-3">
            <div class="col-12 text-center" style="text-align: center">
              <h3 style="text-align: center">No Products found!</h3>
            </div>
            <div class="col-12 text-center" style="text-align: center">
              <h5 style="text-align: center">
                You can
                <router-link :to="`/manage/products`">Add Products</router-link>
                or
                <a
                  v-if="getUserType != 'wix'"
                  href="javascript:void(0)"
                  @click="
                    () => {
                      isLoadingImport = true;
                      $emit('importItems', {
                        title: 'saveProducts',
                        callBackFunction: () => {
                          isLoadingImport = false;
                        },
                      });
                    }
                  "
                >
                  import all products</a
                >
                from your store
              </h5>
            </div>
          </div>
        </div>
      </div>
      <b-skeleton type="input" v-else-if="isLoadingProducts"></b-skeleton>
      <div class="" v-else>
        <label
          for="search-input"
          class="productSearchInput col-12 row align-items-center mx-1 pr-3 py-1"
        >
          <label class="m-0" style="cursor: pointer">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M9.5 3C11.2239 3 12.8772 3.68482 14.0962 4.90381C15.3152 6.12279 16 7.77609 16 9.5C16 11.11 15.41 12.59 14.44 13.73L14.71 14H15.5L20.5 19L19 20.5L14 15.5V14.71L13.73 14.44C12.59 15.41 11.11 16 9.5 16C7.77609 16 6.12279 15.3152 4.90381 14.0962C3.68482 12.8772 3 11.2239 3 9.5C3 7.77609 3.68482 6.12279 4.90381 4.90381C6.12279 3.68482 7.77609 3 9.5 3ZM9.5 5C7 5 5 7 5 9.5C5 12 7 14 9.5 14C12 14 14 12 14 9.5C14 7 12 5 9.5 5Z" fill="#73738D"/>
</svg>
          </label>
          <input
            type="text"
            id="search-input"
            class="mx-2 flex-grow-1"
            v-model="searchProduct"
            placeholder="Search for Product"
          />
          <b-dropdown
            size="sm"
            v-b-tooltip.hover.bottom="'Filter'"
            variant="link"
            dropright
            toggle-class="text-decoration-none"
            no-caret
          >
            <template #button-content>
              <i class="fa-solid fa-filter product-icon"></i>
            </template>
            <b-dropdown-item-button @click="openCollapse('price')"
              >Price</b-dropdown-item-button
            >
            <b-dropdown-item-button @click="openCollapse('tags')"
              >Tags</b-dropdown-item-button
            >
            <b-dropdown-item-button @click="openCollapse('collection')"
              >Collections</b-dropdown-item-button
            >
            <b-dropdown-item-button @click="openCollapse('vendors')"
              >Vendors</b-dropdown-item-button
            >
          </b-dropdown>
        </label>
      </div>
      <div class="">
        <b-collapse
          :visible="productFilters.isFilterVisible"
          id="filter-collapse"
          class="mt-2"
        >
          <div class="w-100">
            <!-- Price-->

            <div class="" v-if="productFilters.price.isActive">
              <div class="productSearchInput col-12 ml-1 align-items-center">
                <div class="d-flex align-items-center justify-content-between">
                  <div class="col-4">
                    <b-form-select
                      v-model="productFilters.price.selectedOption"
                      size="sm"
                      class="product-match-select m-0"
                    >
                      <b-form-select-option selected disabled :value="null">
                        Select Price
                      </b-form-select-option>
                      <b-form-select-option value="above">
                        Above
                      </b-form-select-option>
                      <b-form-select-option value="between">
                        b/w
                      </b-form-select-option>
                      >
                    </b-form-select>
                  </div>
                  <div
                    class="w-100 col-7"
                    v-if="productFilters.price.selectedOption == 'above'"
                  >
                    <b-form-input
                      name="above-price"
                      v-model="productFilters.price.above"
                      placeholder="Above Price"
                      type="number"
                      class="input-price"
                      size="sm"
                    ></b-form-input>
                  </div>
                  <div
                    class="col-4 d-flex align-items-center"
                    v-if="productFilters.price.selectedOption == 'between'"
                  >
                    <label for="from" class="m-0" style="font-size: 13px"
                      >From:</label
                    >
                    <b-form-input
                      id="from"
                      name="From"
                      class="input-price py=0"
                      v-model="productFilters.price.start"
                      placeholder="Price"
                      type="number"
                      size="sm"
                    ></b-form-input>
                  </div>
                  <div
                    class="col-3 flex-1 d-flex align-items-center"
                    v-if="productFilters.price.selectedOption == 'between'"
                  >
                    <label for="to" class="m-0" style="font-size: 13px"
                      >To:</label
                    >
                    <b-form-input
                      id="to"
                      v-model="productFilters.price.end"
                      placeholder="Price"
                      type="number"
                      class="input-price py-0"
                      size="sm"
                    ></b-form-input>
                  </div>
                  <div class="col-1 w-100 d-flex px-0 justify-content-end">
                    <i
                      class="fa fa-times-circle product-icon float-right"
                      @click="resetFilter"
                      aria-hidden="true"
                      style="cursor: pointer"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
            <!-- Vendors -->
            <div v-if="productFilters.vendors.isActive">
              <div
                class="col-12 d-flex ml-1 align-items-center productSearchInput"
              >
                <b-form-select
                  size="sm"
                  v-model="productFilters.vendors.selectedOPtion"
                  class="product-match-select m-0"
                >
                  <b-form-select-option selected disabled :value="null">
                    Select Vendor
                  </b-form-select-option>
                  <b-form-select-option
                    :value="vendor.title"
                    v-for="(vendor, index) in VendorList"
                    :key="index"
                  >
                    {{ vendor.title }}
                  </b-form-select-option>

                  > </b-form-select
                ><i
                  class="fa fa-times-circle product-icon float-right"
                  @click="resetFilter"
                  aria-hidden="true"
                  style="cursor: pointer"
                ></i>
              </div>
              <div class="col-12"></div>
            </div>
            <!-- Collections -->
            <div class="" v-if="productFilters.collections.isActive">
              <div
                class="col-12 d-flex ml-1 align-items-center productSearchInput"
              >
                <multiselect
                  class="product-match-select"
                  v-model="productFilters.collections.selectedOPtion"
                  :options="this.CollectionList.map((tag) => tag.title)"
                  placeholder="Collections"
                  :multiple="true"
                  :taggable="true"
                ></multiselect>

                <i
                  class="fa fa-times-circle product-icon float-right"
                  @click="resetFilter"
                  aria-hidden="true"
                  style="cursor: pointer"
                ></i>
              </div>
              <div class="col-12"></div>
            </div>
            <!-- Tags -->
            <div class="" v-if="productFilters.tags.isActive">
              <div
                class="col-12 d-flex ml-1 align-items-center productSearchInput"
              >
                <multiselect
                  class="product-match-select"
                  v-model="productFilters.tags.selectedOPtion"
                  :options="this.TagsList.map((tag) => tag.title)"
                  placeholder="Tag"
                  :multiple="true"
                  :taggable="true"
                ></multiselect>
                <i
                  class="fa fa-times-circle product-icon float-right"
                  @click="resetFilter"
                  aria-hidden="true"
                  style="cursor: pointer"
                ></i>
              </div>
              <div class="col-12"></div>
            </div>
          </div>
        </b-collapse>
      </div>
      <div
        v-if="isLoadingProducts"
        class="col-12 mt-4 d-flex justify-content-end"
      >
        <b-skeleton class="w-25"></b-skeleton>
      </div>
      <div v-else class="col-12 mt-4 d-flex justify-content-between">
        <div class="d-flex sectionTitle">
          Turn On Product Score:
          <span
            class="custom-control mx-2 custom-switch"
            @click="checkUpperPlanUser"
          >
            <input
              type="checkbox"
              class="custom-control-input"
              v-model="isVisibleProductScore"
              :id="`show-address1-${Date.now()}`"
              :disabled="!isUpperPlanUser"
            />
            <label
              class="custom-control-label"
              :for="`show-address1-${Date.now()}`"
            ></label>
          </span>
        </div>
        <div class="filterBtn mr-2" @click="resetFilter()" v-if="checkReset">
          Reset Filter
        </div>
        <div
          class="filterBtn "
          v-if="!areNotAvailableProducts && getProducts.length > 0"
          @click.stop="bulkSelectProducts"
        >
          {{ isSelectAll ? "Select All" : "Unselect All" }}
        </div>
      </div>
      <div
        class="w-100 p-3 productBox"
        v-if="isLoadingProducts"
        style="border: none"
      >
        <div class="skel">
          <div class="mb-2 py-1" v-for="index in 5" :key="index">
            <div class="d-flex align-items-center">
              <b-skeleton type="avatar" animation="throb"></b-skeleton>
              <b-skeleton
                type="input"
                class="ml-2 w-86"
                animation="throb"
              ></b-skeleton>
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div
        class="w-100 p-3 productBox"
        v-if="!areNotAvailableProducts && getProducts.length > 0"
        style="border: none"
      >
        <div
          :class="
            isSelectedProduct(product) ? 'selectedProduct' : null
           
          "
          class=" mb-3 py-1 product-row"
          v-for="(product, prodIndex) in getProducts"
          :key="`${product.id}-${prodIndex}`"
        >
          <div class="d-flex align-items-center product ">
            <div
              class="d-flex px-0 align-items-center"
              style="flex: 0.95; cursor: pointer"
            >
              <div
                class="d-flex px-0 align-items-center "
                @click="selectProduct(product)"
                style="cursor: pointer;flex:0.1"
              >
                <div class="">
                  <div class="custom-control custom-checkbox" role="group">
                    <input
                      type="checkbox"
                      :id="`quest-${prodIndex}`"
                      class="custom-control-input position-static"
                      aria-label="Never"
                      :value="{
                        product_id: product.id,
                        question_option_id:
                          question.options[selected.optionIndex].id,
                        score:
                          question.options[selected.optionIndex]
                            .selected_products[
                            getProductIndex(selected.optionIndex, product.id)
                          ]?.score,
                      }"
                      v-model="
                        question.options[selected.optionIndex].selected_products
                      "
                      @change="selectProduct(product)"
                    />
                    <label
                      :for="`quest-${prodIndex}`"
                      class="custom-control-label"
                    ></label>
                  </div>
                </div>
                <div class="img">
                  <div v-if="checkFileType(product.image) == 'video'">
                    <video
                      ref="videoRef"
                      :src="`${product.image}`"
                      alt=""
                      class="img-thumbnail mr-2"
                      style="
                        height: 50px;
                        width: 50px;
                        border-radius: 50%;
                        object-fit: cover;
                      "
                      controls
                    ></video>
                  </div>
                  <LazyImage
                    v-else
                    :src="productImage(product.image)"
                    :key="`${product.id}-${product.image}`"
                    alt=""
                    class="product-image"
                  />
                </div>
              </div>
              <div
                class="singleProduct d-flex flex-column justify-content-between  pl-2"
                style="flex:0.9"
              >
                <p class="title text-left mb-1 p-0" @click="selectProduct(product)">
                  {{ product.title }}
                </p>
                <p class="price text-left m-0 p-0" @click="selectProduct(product)">
                  {{ product.price }}
                </p>
               
              </div>
            </div>
            <div class="" style="flex: 0.05;">
              <div
                  v-if="
                    isVisibleProductScore &&
                    getProductIndex(selected.optionIndex, product.id) !== null
                  "
                  class="mx-2 d-flex  justify-content-center align-items-center parent-div"
                  style=" z-index: 11"
                > 

                <div class="productScoreWrapper d-flex  justify-content-center align-items-center">

                  <div class="incDecBtn mr-1" @click="
                      scoreCounter(
                        'decrement',
                        selected.optionIndex,
                        product.id
                      )
                    ">
                    -
                  </div>
                  <div>
                    <input
                    min="0"
                    type="number"
                    class="counter-input"
                    name=""
                    id=""
                    @input="
                      handleProductScoreInput(
                        $event,
                        selected.optionIndex,
                        product.id
                      )
                    "
                    :value="
                      question.options[selected.optionIndex].selected_products[
                        getProductIndex(selected.optionIndex, product.id)
                      ]?.score
                    "
                  />
                  </div>
                  <div class="incDecBtn ml-1" @click="
                      scoreCounter(
                        'increment',
                        selected.optionIndex,
                        product.id
                      )
                    ">+</div>
                </div>
                </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="w-100 p-3 productBox"
        v-if="
          !this.isLoadingProducts && products.all.length > 0 && getProducts <= 0
        "
        style="border: none"
      >
        <div
          class="d-flex flex-column h-100 justify-content-center align-items-center text-center mb-2 py-1"
        >
          <div
            class="btn"
            v-b-tooltip.hover.top="'Reset Filter'"
            @click="resetFilter()"
          >
            <i class="fa-solid fa-arrow-rotate-right"></i>
          </div>
          <h5>No products were found matching your filter</h5>
        </div>
      </div>
    </section>
    <product-variant
      v-else
      @backFromVariant="backFromVariant()"
      :isLoadingProducts="isLoadingVariantProduct"
      :products="selectedVariantProducts"
      :selected="selected"
    ></product-variant>
  </div>
</template>
<script>
import LoaderComp from "../../component/LoaderComp.vue";
import Multiselect from "vue-multiselect";
import axios from "axios";
import ProductVariant from "./ProductVariant.vue";
import { mapGetters, mapMutations } from "vuex";
export default {
  props: {
    productStoreMapStatus: Number,
    products: Object,
    selected: Object,
    question: Object,
    TagsList: Array,
    CollectionList: Array,
    VendorList: Array,
    isLoadingProducts: Boolean,
  },
  components: {
    ProductVariant,
    Multiselect,
    LoaderComp,
  },
  computed: {
    UserPlanData() {
      return this.getPlanData;
    },
    isUpperPlanUser() {
      let allowedPlan = ["Pro", "Plus", "Enterprise plan Special"];
      return allowedPlan.includes(this.UserPlanData.name);
    },
    areNotAvailableProducts() {
      return this.isLoadingProducts == false && this.products.all.length <= 0
        ? true
        : false;
    },
    ...mapGetters([
      "getProductScoreMapStatus",
      "getEditorSettings",
      "GetTokenFromMetaTag",
      "getQuizID",
      "getNavbarData",
      "getPlanData",
    ]),
    getUserType() {
      return this.getNavbarData.userType;
    },
    checkReset() {
      for (let index in this.productFilters) {
        if (index != "isFilterVisible") {
          if (this.productFilters[index].selectedOPtion != null)
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.showResetBtn = true;
        }
      }
      if (
        this.productFilters.price.above != "" ||
        this.productFilters.price.start != "" ||
        this.productFilters.price.end != ""
      )
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.showResetBtn = true;

      return this.showResetBtn;
    },
    getSortedProducts() {
      if (
        this.question.options[this.selected.optionIndex].selected_products
          .length
      ) {
        let sortedProducts = [];

        //  find Selected Products from All Products
        this.question.options[
          this.selected.optionIndex
        ].selected_products.forEach((selectedSingleProduct) => {
          const selectedProducts = this.products.all.find(
            (product) => product.id === selectedSingleProduct.product_id
          );
          if (selectedProducts) {
            sortedProducts.push(selectedProducts);
          }
        });

        // Find remaining non selected Products
        const otherProducts = this.products.all.filter(
          (product) =>
            !this.question.options[
              this.selected.optionIndex
            ].selected_products.some(
              (singleSelectedProduct) =>
                singleSelectedProduct.product_id === product.id
            )
        );
        sortedProducts = sortedProducts.concat(otherProducts);

        return sortedProducts;
      } else {
        return this.products.all;
      }
    },
    getProducts() {
      // return this.products.all.filter(item => {
      return this.getSortedProducts.filter((item) => {
        return (
          item.title.toLowerCase().includes(this.searchProduct.toLowerCase()) &&
          (this.productFilters.price.above == "" ||
            (item.price > 0 &&
              item.price >= this.productFilters.price.above)) &&
          (this.productFilters.price.start == "" ||
            this.productFilters.price.end == "" ||
            (item.price > 0 &&
              item.price >= this.productFilters.price.start &&
              item.price <= this.productFilters.price.end)) &&
          (this.productFilters.tags.selectedOPtion == null ||
            this.productFilters.tags.selectedOPtion.length <= 0 ||
            (item.tags !== null &&
              Array.isArray(item.tags) && item.tags.length &&
              item.tags.some((tag) => {
                if(tag != ''){
                  return this.productFilters.tags.selectedOPtion.find(
                  (selected) => selected == tag
                );
                } else {
                  return false
                }
               
              }))) &&
          (this.productFilters.collections.selectedOPtion == null ||
            this.productFilters.collections.selectedOPtion.length <= 0 ||
            (item.collections !== null &&
              Array.isArray(item.collections) &&
              item.collections.some((collection) => {
                return this.productFilters.collections.selectedOPtion.find(
                  (selected) => {
                    return selected == collection.title;
                  }
                );
              }))) &&
          (this.productFilters.vendors.selectedOPtion == null ||
            (item.vendor !== null &&
              item.vendor.includes(this.productFilters.vendors.selectedOPtion)))
        );
      });
    },
  },
  watch: {
    isVisibleProductScore(newValue) {
      if (this.isUpperPlanUser) {
        const value = newValue ? 1 : 0;
        this.toggleProductScore(value);
      }
    },
  },
  data() {
    return {
      isVisibleProductScore: this.productStoreMapStatus == 1 ? true : false,
      isLoadingImport: false,
      tagOptions: [],
      selectedVariantProducts: [],
      isLoadingVariantProduct: false,
      isOpenVariant: false,
      skeletonCount: 5,
      searchProduct: "",
      isSelectAll: true,
      showResetBtn: false,
      productFilters: {
        price: {
          isActive: false,
          selectedOption: null,
          above: "",
          start: "",
          end: "",
        },
        tags: {
          isActive: false,
          allTags: [],
          selectedOPtion: null,
        },
        vendors: {
          isActive: false,
          selectedOPtion: null,
          allVendors: [],
        },
        collections: {
          isActive: false,
          allCollections: [],
          selectedOPtion: null,
        },
        isFilterVisible: false,
      },
    };
  },
  methods: {
    checkUpperPlanUser() {
      if (!this.isUpperPlanUser) {
        this.triggerUpgradeModal();
      }
    },
    triggerUpgradeModal() {
      let obj = {
        isOpen: true,
        title: "Upgrade to Access Product Scoring Feature",
        subText: `This powerful tool allows you to assign scores to products, enhancing the accuracy and relevance of your quiz results. Make every recommendation count by tailoring them to your audience's preferences. For detailed guidance on utilizing this feature, please visit: <a href="https://support.quizell.com/blogs/how-to-use/how-to-use-the-product-score" target="_blank" style="color:#FFC000;text-decoration:underline">How to Use the Product Score</a>.`,
      };

      this.setUpgradePlansModal(obj);
    },
    ...mapMutations(["setUpgradePlansModal"]),
    async toggleProductScore(value) {
      await axios
        .post("toggleProductScoreMapSetting", {
          quizId: this.getQuizID,
          product_score_map_status: value,
        })
        .then((response) => {
          if (response.status == 200) {
            const text = `Product score has been ${
              value ? "activated" : "deactivated"
            }`;
            this.$toasted.show(text, {
              theme: "toasted-primary",

              position: "bottom-center",
              duration: 2000,
            });
          } else
            this.$toasted.show("Error occurred ", {
              theme: "toasted-primary",
              type: "error",
              position: "bottom-center",
              duration: 2000,
            });
        })
        .catch(() => {
          this.$toasted.show("Error occurred ", {
            theme: "toasted-primary",
            type: "error",
            position: "bottom-center",
            duration: 2000,
          });
        });
    },
    findClosestParent(element, className) {
      while (element && !element.classList.contains(className)) {
        element = element.parentNode;
      }
      return element;
    },
    scoreCounter(event, selectedOptionIndex, productId) {
      if (selectedOptionIndex > -1) {
        const index = this.getProductIndex(selectedOptionIndex, productId);
        if (index > -1) {
          if (event == "increment") {
            this.question.options[selectedOptionIndex].selected_products[
              index
            ].score += 1;
          }
          if (event == "decrement") {
            this.question.options[selectedOptionIndex].selected_products[
              index
            ].score -= 1;
          }
        }
      }
    },
    handleProductScoreInput(event, selectedOptionIndex, productId) {
      const index = this.getProductIndex(selectedOptionIndex, productId);

      if (index > -1) {
        this.question.options[selectedOptionIndex].selected_products[
          index
        ].score = event.target.value;
      }
    },

    getProductIndex(selectedOprtionIndex, productId) {
      const productArr =
        this.question.options[selectedOprtionIndex].selected_products;
      if (productArr.length > 0) {
        const index = productArr.findIndex(
          ({ product_id }) => product_id == productId
        );
        return index > -1 ? index : null;
      }
      return null;
    },
    async openVariant(id) {
      this.isOpenVariant = true;
      this.selectedVariantProducts = [];
      this.isLoadingVariantProduct = true;
      await axios
        .post("previewProductVariants", {
          product_id: id,
          quizId: parseInt(localStorage.getItem("QuizID")),
        })
        .then((response) => {
          if (response.status != 200) {
            this.$toasted.show("Error occurred ", {
              theme: "toasted-primary",
              type: "error",
              position: "bottom-center",
              duration: 2000,
            });
            return;
          }
          this.selectedVariantProducts = response.data.data;
        })
        .catch(() => {
          this.$toasted.show("Error occurred ", {
            theme: "toasted-primary",
            type: "error",
            position: "bottom-center",
            duration: 2000,
          });
        });
      this.isLoadingVariantProduct = false;
    },
    backFromVariant() {
      this.isOpenVariant = false;
    },
    bulkSelectProducts() {
      let products = this.getProducts;
      if (this.isSelectAll) {
        this.isSelectAll = false;

        products.forEach((product) => {
          let checkDuplicate = {
            product_id: product.id,
            question_option_id:
              this.question.options[this.selected.optionIndex].id,
            score:
              this.question.options[this.selected.optionIndex]
                .selected_products[
                this.getProductIndex(this.selected.optionIndex, product.id)
              ]?.score,
          };
          if (
            !this.question.options[
              this.selected.optionIndex
            ].selected_products.includes(checkDuplicate)
          )
            this.question.options[
              this.selected.optionIndex
            ].selected_products.push({
              product_id: product.id,
              question_option_id:
                this.question.options[this.selected.optionIndex].id,
              score:
                this.question.options[this.selected.optionIndex]
                  .selected_products[
                  this.getProductIndex(this.selected.optionIndex, product.id)
                ]?.score || 0,
            });
        });
      } else {
        this.question.options[this.selected.optionIndex].selected_products = [];
        this.isSelectAll = true;
      }
    },
    resetFilter() {
      this.searchProduct = "";
      this.productFilters.isFilterVisible = false;
      this.showResetBtn = false;

      for (let index in this.productFilters) {
        if (index != "isFilterVisible") {
          this.productFilters[index].isActive = false;
          this.productFilters[index].selectedOPtion = null;
        }
      }
      this.productFilters.price.start = "";
      this.productFilters.price.end = "";
      this.productFilters.price.above = "";
    },
    openCollapse(name) {
      this.productFilters.isFilterVisible = true;
      for (let index in this.productFilters) {
        if (index != name && index != "isFilterVisible") {
          this.productFilters[index].isActive = false;
          // this.productFilters[index].selectedOPtion = null
        }
      }
      // this.productFilters.price.start = ''
      // this.productFilters.price.end = ''
      // this.productFilters.price.above = ''
      if (name === "price") this.productFilters.price.isActive = true;
      if (name === "vendors") this.productFilters.vendors.isActive = true;
      if (name === "tags") this.productFilters.tags.isActive = true;
      if (name === "collection")
        this.productFilters.collections.isActive = true;
    },
    isSelectedProduct(product) {
      const findIndex = this.question.options[
        this.selected.optionIndex
      ].selected_products.findIndex((x) => x.product_id == product.id);
      if (findIndex > -1) {
        return true;
      } else {
        return false;
      }
    },

    checkFileType(file) {
      let fileType;
      let allowedImageExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
      let allowedVideoExtensions = /(\.mp4)$/i;

      if (!allowedImageExtensions.exec(file)) {
        if (allowedVideoExtensions.exec(file)) {
          fileType = "video";
        } else {
          fileType = "unknown";
        }
      } else {
        fileType = "image";
      }

      return fileType;
    },
    productImage(image) {
      if (image) return image.startsWith("upload") ? "/" + image : image;
      return "https://images.quizell.com/default/default_image.png";
    },

    selectProduct(product) {
      // question.options[selected.optionIndex].selected_products
      const index = this.question.options[
        this.selected.optionIndex
      ].selected_products.findIndex(
        (productOption) => productOption.product_id == product.id
      );

      if (index > -1) {
        this.question.options[
          this.selected.optionIndex
        ].selected_products.splice(index, 1);
      } else {
        const obj = {};
        obj.product_id = product.id;
        obj.question_option_id =
          this.question.options[this.selected.optionIndex].id;
        obj.score = 0;

        this.question.options[this.selected.optionIndex].selected_products.push(
          obj
        );
      }
    },
  },
  mounted() {},
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>

.productScoreWrapper{
  display: flex;
  align-items: center;
  padding:5px 12px;
  justify-content: center;
}
.productScoreWrapper .incDecBtn{
 text-align: center;
 font-size: 20px;
 font-weight: 600;
 cursor: pointer;
 user-select: none;
 padding: 2px 8px;
 border-radius: 8px;
border: 1px solid #EDEDED;
background: #FFF;
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.productScoreWrapper input {
  width: 40px;
  font-size: 18px;
  outline: none;
  border: none;
  text-align: center;

}
.counter-input {
  width: 40px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.counter-control:active {
  background: #ccc;

  /* box-shadow: */
}
.product-image {
  height: 50px;
  width: 50px;
  border-radius: 8px;
  object-fit: cover;
  background-size: cover;
}

.product-icon {
  color: #4d1b7e;
}

.product-icon:hover {
  color: black;
}


.productSearchInput {
  border: 0.8px solid #18191C14;
  box-sizing: border-box;
  border-radius: 6px;
  background: #ffffff;
}

.productSearchInput input {
  background: transparent;
  border: none;
  outline: none;
}

.productSearchInput input:focus {
  border: none;
  outline: none;
}

.filterBtn {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  align-items: center;
  letter-spacing: 0.04em;
  color: #4d1b7e;
  cursor: pointer;
}

.filterBtn:hover {
  color: black;
}

.w-86 {
  width: 86%;
}

.productBox {
  height: 50vh;
  /* border: 0.5px solid #b3afb6; */
  box-sizing: border-box;
  border-radius: 10px;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

.productBox .product {
  border-radius: 6px;
}

.productBox .product:hover {
  background: #b3afb614;
}

.variant-btn {
  /* display: none; */
  display: block;
  /* font-size: 14px; */
  border: 1px solid #b3afb6;
  border-radius: 12px;
  outline: none;
}

.selectedProduct .product:hover .variant-btn {
  border: 1.5px solid #4d1b7e;
  color: #4d1b7e !important;
}

.selectedProduct .product:hover .variant-btn .variant-text {
  color: #4d1b7e !important;
}

/* .product:hover .variant-btn {
  display: block;
} */

.singleProduct .title {
  color: #1F1F28;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 600 !important;
line-height: normal;
}

.singleProduct .price {
  color: #4D1B7E !important;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 600 !important;
line-height: normal;
}

.product-match-select:focus {
  border-color: transparent !important;
  outline: 0 !important;
  box-shadow: none !important;
}

.product-match-select {
  border-color: transparent !important;
  outline: 0 !important;
  box-shadow: none !important;
}

.multiselect__option--highlight {
  background: #b3afb626 !important;
  color: black !important;
}

.multiselect__option--selected {
  background: #b3afb6 !important;
  color: black !important;
}

.multiselect__option--selected.multiselect__option--highlight:after {
  background: #b3afb626 !important;
  content: attr(data-deselect);
  color: black !important;
}

.multiselect__option--highlight:after {
  content: attr(data-select);
  background: #f4f3f4 !important;
  color: black !important;
}

.multiselect__option--selected:after {
  content: attr(data-selected);
  color: inherit !important;
  background: inherit;
}

.multiselect__tag {
  background: #613c86 !important;
}

.multiselect__tags {
  min-height: 40px;
  display: block;
  padding: 8px 40px 0 8px;
  border-radius: 5px;
  border: none !important;
  background: #fff;
  font-size: 14px;
}

.multiselect__tag-icon:after {
  color: #fbfbfb !important;
}

.input-price:focus {
  color: #495057;
  background-color: #fff;
  border-color: none !important;
  outline: 0;
  box-shadow: none !important;
}
</style>
